import axios, { AxiosError, AxiosRequestConfig } from 'axios';

export const apiPublicIam = ({ ...options }: AxiosRequestConfig) => {
  const instance = axios.create({
    baseURL: import.meta.env.NEXUS_API_IAM_URL,
    ...options,
  });

  instance.interceptors.response.use(
    (config) => {
      return config;
    },
    async (error: AxiosError) => {
      console.log(error);
      return Promise.reject(error);
    }
  );

  return instance;
};
