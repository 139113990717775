import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { HttpData } from '~/utils/network/HttpData';
import { apiPublicIam } from '../api';

export const primaryKey = 'auth';

export type AuthLoginRequest = {
  email: string;
  password: string;
};

export type AuthLoginResponse = {
  access_token: string;
  id: number;
  name: string;
  email: string;
  refresh_token: string;
  avatar_url: string;
  role: string;
  username: string;
};

export const useLogin = (
  config: UseMutationOptions<HttpData<AuthLoginResponse>>
) => {
  return useMutation<HttpData<AuthLoginResponse>, Error, void>({
    mutationKey: ['auth', 'login'],

    mutationFn: (payload) => {
      return apiPublicIam({})
        .post(
          '/iam/user/v1/login-admin',
          payload as unknown as AuthLoginRequest
        )
        .then((response) => response.data);
    },
    onSuccess(data, variables, context) {
      return { data, variables, context };
    },
    ...config,
  });
};
